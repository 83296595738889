import { env } from '@/env';
import { signIn } from 'next-auth/react';
import { useEffect } from 'react';

// needed for e2e tests
export default function Component() {
  useEffect(() => {
    signIn('google', {
      callbackUrl: env.NEXT_PUBLIC_URL
    }, {
      prompt: 'select_account'
    });
  }, []);
}